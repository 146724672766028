import oddsMapper from './odds-mapper';

const mappedOdds = [
  1, 1.01, 1.02, 1.03, 1.04, 1.05, 1.06, 1.07, 1.08, 1.09, 1.1, 1.11, 1.12, 1.13, 1.14, 1.15,
  1.16, 1.17, 1.18, 1.19, 1.2, 1.22, 1.25, 1.28, 1.3, 1.33, 1.35, 1.36, 1.4, 1.44, 1.45, 1.47,
  1.5, 1.53, 1.57, 1.6, 1.62, 1.63, 1.66, 1.7, 1.72, 1.8, 1.83, 1.9, 1.91, 1.95, 2, 2.05, 2.1,
  2.2, 2.25, 2.3, 2.38, 2.4, 2.5, 2.6, 2.63, 2.7, 2.75, 2.8, 2.88, 2.9, 3, 3.1, 3.13, 3.2, 3.25,
  3.3, 3.38, 3.4, 3.5, 3.6, 3.75, 3.8, 4, 4.2, 4.33, 4.5, 4.6, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5,
  9, 9.5, 10, 11, 20, 30, 40, 50, 60, 70, 80, 90, 100, 1000,
];

const findClosest = (value) => mappedOdds.reduce(
  (prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev),
);

const getMappedOddValue = (price, format) => {
  const odd = price?.decimalValue;
  if (!odd || odd === '-' || odd === 'Infinity' || odd === 'NaN') return '-';
  if (format === 'decimal') return odd > 999 ? odd : parseFloat(odd).toFixed(2);
  if (format === 'probability') {
    const probability = parseFloat((price.probability + price.bias) * 100).toFixed(1);
    return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
  }
  const closestOdd = findClosest(odd);
  return (oddsMapper[closestOdd] || {})[format] || '-';
};

export default {
  getMappedOddValue,
};
